@import './colores.scss';

.calendario-evento-nuevo {
	div{
		border: 1px solid $oa-principal2 !important;
		background-color: $oa-principal !important;
	}
	span {
		color:white !important;
	}
}

.calendario-evento-guardado {
	div{
		border: 1px solid $oa-gris !important;
		background-color: $oa-gris-claro !important;
	}
	span {
		color: $oa-gris-oscuro !important;
	}
}
