@import "colores.scss";

.texto-ayuda {
	color: rgb(97, 97, 97) !important;
}
.roboto-thin {
	font-family: "Roboto" !important;
	font-weight: 300 !important;
}
.roboto-regular {
	font-family: "Roboto" !important;
	font-weight: 400 !important;
}
.roboto-medium {
	font-family: "Roboto" !important;
	font-weight: 500 !important;
}
.txt-6 {
	font-size: 6px !important;
}
.txt-8 {
	font-size: 8px !important;
}
.txt-10 {
	font-size: 10px !important;
}
.txt-12 {
	font-size: 12px !important;
}
.txt-14 {
	font-size: 14px !important;
}
.txt-16 {
	font-size: 16px !important;
}
.txt-18 {
	font-size: 18px !important;
}
.txt-20 {
	font-size: 20px !important;
}
.txt-22 {
	font-size: 22px !important;
}
.txt-24 {
	font-size: 24px !important;
}
.txt-26 {
	font-size: 26px !important;
}
.txt-28 {
	font-size: 28px !important;
}
.txt-30 {
	font-size: 30px !important;
}
.txt-32 {
	font-size: 32px !important;
}
.txt-34 {
	font-size: 34px !important;
}
.txt-36 {
	font-size: 36px !important;
}
.txt-titulo {
	font-size: 18px !important;
}
.txt-center {
	text-align: center !important;
}
.txt-link {
	text-decoration: underline;
	color: #0066c0;
	cursor: pointer;
}
.txt-editable-link-izq {
	cursor: pointer;
	&:hover {
		color: $oa-color-link !important;
		&::before {
			-webkit-font-smoothing: antialiased !important;
			font-family: "Font Awesome 5 Free" !important;
			font-weight: 900 !important;
			content: " \f044 \ " !important;
		}
	}
}
.txt-editable-link-der {
	cursor: pointer;
	&:hover {
		color: $oa-color-link !important;
		&::after {
			-webkit-font-smoothing: antialiased !important;
			font-family: "Font Awesome 5 Free" !important;
			font-weight: 900 !important;
			content: " \f044 \ " !important;
		}
	}
}
.txt-link:hover {
	color: #c45500;
}

.cursiva {
	font-style: italic;
}

/* COLORES VARIOS */
.gris {
	color: $oa-gris !important;
}
.gris-claro {
	color: $oa-gris-claro !important;
}
.gris-oscuro {
	color: $oa-gris-oscuro !important;
}
.azul {
	color: rgb(62, 146, 255) !important;
}
.verde {
	color: rgb(40, 179, 40) !important;
}
.rojo {
	color: rgb(226, 32, 32) !important;
}
.amarillo {
	color: rgb(255, 239, 14) !important;
}

.color-principal {
	color: $oa-principal;
}
.color-principal2 {
	color: $oa-principal2;
}
.color-principal3 {
	color: $oa-principal3;
}
.color-secundario {
	color: $oa-secundario;
}
.color-secundario2 {
	color: $oa-secundario2;
}
.color-secundario3 {
	color: $oa-secundario3;
}
.color-accent {
	color: $oa-accent;
}
.color-accent2 {
	color: $oa-accent2;
}
.color-accent3 {
	color: $oa-accent3;
}
.color-accent-secundario {
	color: $oa-accent-secundario;
}
.color-accent-secundario2 {
	color: $oa-accent-secundario2;
}
.color-accent-secundario3 {
	color: $oa-accent-secundario3;
}
