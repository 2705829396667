


body {
  font-size: .9rem;
}

.page-wrapper.toggled .sidebar {
  left: -300px;
}

.sidebar .ps.ps--scrolling-y>.ps__rail-y,
.sidebar>.ps.ps--scrolling-x>.ps__rail-x,
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
}

.sidebar .ps.ps--active-y>.ps__rail-y:hover,
.sidebar>.ps.ps--active-y>.ps__rail-y.ps--clicking {
  width: 10px !important;
}

.ps__thumb-y {
  background-color: #fff !important;
  width: 6px!important;
  border-radius: 0px!important;
  opacity: .5;

}
