// .apexcharts-tooltip {
// 	background: #ffffff !important;
//     color: rgb(105, 105, 105) !important;
// 	.apexcharts-tooltip-title{
// 		background-color: rgb(246, 246, 246) !important;
// 	}
// }
.apexcharts-xcrosshairs{
	display: none;
}
.apexcharts-tooltip {
	border: 0 !important;
	-webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2) !important;
	-moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2) !important;
	box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2) !important;
}
.apxtooltip {
	width: 170px !important;
	.apxtooltip__title {
		padding: 4px 8px !important;
		// font-weight: lig !important;
		color: rgb(59, 59, 59) !important;
		background-color: rgb(250, 250, 250) !important;
		font-size: 0.75rem !important;
	}
	.apxtooltip__serie {
		padding: 4px !important;
		padding-left: 12px !important;
		color: rgb(77, 77, 77) !important;
		background-color: rgba(255, 255, 255, 0.2) !important;
		font-weight: bold !important;
		font-size: 0.8rem !important;
		.apxtooltip__serie--nombre {
			font-weight: lighter !important;
		}
	}
}
