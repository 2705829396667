table.mat-mdc-table {
  .mat-mdc-header-cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.30);
  }
  .mat-mdc-footer-cell {
    font-weight: bold;
    border-top: 1px solid rgba(0, 0, 0, 0.30);
  }
  .mat-mdc-cell {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
