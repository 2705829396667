.cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
  background-color: white;

  .mdc-list-item--selected {
    span {
      color: rgb(52, 52, 52);
    }
  }

}
