$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
$space: 10;

@each $space in $spaceamounts {
    .m-#{$space} {
        margin: #{$space*$space}px !important;
    }

    .mx-#{$space} {
        margin-left: #{$space*$space}px !important;
        margin-right: #{$space*$space}px !important;
    }

    .my-#{$space} {
        margin-top: #{$space*$space}px !important;
        margin-bottom: #{$space*$space}px !important;

    }

    .ml-#{$space} {
        margin-left: #{$space*$space}px !important;
    }

    .mr-#{$space} {
        margin-right: #{$space*$space}px !important;
    }

    .mb-#{$space} {
        margin-bottom: #{$space*$space}px !important;
    }

    .mt-#{$space} {
        margin-top: #{$space*$space}px !important;
    }

    .p-#{$space} {
        padding: #{$space*$space}px !important;
    }

    .px-#{$space} {
        padding-left: #{$space*$space}px !important;
        padding-right: #{$space*$space}px !important;
    }

    .py-#{$space} {
        padding-top: #{$space*$space}px !important;
        padding-bottom: #{$space*$space}px !important;

    }

    .pl-#{$space} {
        padding-left: #{$space*$space}px !important;
    }

    .pr-#{$space} {
        padding-right: #{$space*$space}px !important;
    }

    .pb-#{$space} {
        padding-bottom: #{$space*$space}px !important;
    }

    .pt-#{$space} {
        padding-top: #{$space*$space}px !important;
    }
}
