/* You can add global styles to this file, and also import other style files */

/* @import url("https://use.fontawesome.com/releases/v5.7.0/css/all.css"); */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

@import "assets/css/movil.scss";
@import "assets/css/sidebar-global.css";
@import "assets/css/texto-estilos.scss";
@import "assets/css/tablas.scss";
@import "assets/css/apexcharts.scss";
@import "assets/css/crm.scss";
@import "assets/css/angular-calendar.scss";
@import "assets/css/components/index.scss";
@import "assets/css/spacing.helper.scss";

html,
body {
  font-size: 95%; // adjust the size with percentage here.
  /* ARREGLOS PARA IOS Y ANDROID*/
  // -webkit-user-select: none !important; /*  NO TEXT SELECTION*/
  -webkit-tap-highlight-color: transparent !important;
  /* NO  HIGHLIGHT */
  -webkit-touch-callout: none !important;
  /* NO CALL-OUTS */
  overscroll-behavior-y: contain !important;
  /* SACAR PULL TO REFRESH  ANDROID*/

  /*****/
  height: 100%;
  margin: 0;
  background-color: #d9d9d9 !important;
  caret-color: #777 !important;
  /*COLOR DEL CURSOR DE TEXTO*/
}

@import "assets/css/impresiones.scss";


.animated.fastest {
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
}

.mat-form-field-label {
  span {
    font-size: 105%;
  }
}

.backdrop {
  // LOADING
  z-index: 1999 !important; //Para que quede arriba de sidebar (2100) y LOADING (1999) pero debajo del sweetalert (2300)
  //ojo es 1999 y  no 2000 por que 2000 tiene la animacion que va arriba
}

.cdk-overlay-container {
  // MODALES
  z-index: 2200 !important; //Para que quede arriba de sidebar (2100) y LOADING (1999) pero debajo del sweetalert (2300)
}

.swal2-container {
  // SWEET ALERT
  z-index: 2300 !important; //Para que quede arriba de sidebar (2100) y LOADING (1999)
}

/*ARREGLO MAT TABLE PARA EDGE Y FIREFOX*/
table.mat-table {
  width: 100% !important;
  background: #ffffff00 !important;
}

/*CASO ESPECIAL PARA PASARELA DE PAGO*/
mat-form-field.fecha-vto div.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

mat-form-field .mat-form-field-outline-start {
  border: solid 2px #ccc;
}

/* MENSAJE DE ALERTA DE TRIAL */
.alerta-prueba {
  padding: 8px;
  background-color: #cbffc5;
  color: #4f4f4f;
}

.alerta-prueba-body {
  padding-top: 18px;
}

.alerta-close {
  float: left;
  position: fixed;
  right: 30px;
  top: 10px;
}

.comentarios {
  width: 100% !important;
  border: #4f4f4f solid 1px;
  border-radius: 5px;
}

/******************************/

.click-aqui {
  text-decoration: underline;
  color: rgb(80, 80, 255);
}

.main-container {
  margin-top: 5px;
  padding: 10px;
}

.mat-card .mat-divider-horizontal {
  position: relative !important;
}

/* INPUTS */
.mat-form-field-prefix {
  //PREFIJO QUE SE LE PONE A LOS INPUTS MAT-PREFIX
  top: 0px !important;
}

mat-form-field.mat-input-no-padding {
  .mat-form-field-wrapper {
    padding: 0 !important;
  }
}

/*MAT MENU - DESPLEGABLE MATERIAL*/
.mat-menu-angosto {
  // para hacerlos mas angostos
  line-height: 35px !important;
  height: 35px !important;
}

/* MAT-TOOLTIP */
.mat-tooltip-grande {
  white-space: pre-wrap !important;
  min-width: 300px !important;
}

/*DIALOGS*/
.dialogo-gris .mat-dialog-container {
  background-color: #f0f0f0 !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

/* SELECT */
select {
  padding-top: 5px !important;
  font-size: 16px !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border: 0.44em solid transparent !important;
}

/* AUTOCOMPLETE */
.agregar-nuevo {
  border-bottom: #ccc 2px solid;
  text-align: center;
}

/*CURSORES*/
.pointer {
  cursor: pointer !important;
}

tr.pointer:hover {
  background-color: rgb(249, 249, 249) !important;
}

/*LINK*/
.link {
  text-decoration: underline !important;
}

/*LISTAS*/
.mat-list-icon {
  vertical-align: middle;
}

/* BOTONES */
.botonera-sticky {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 16px;
  z-index: 1200;
}

button.codigo-barras-gordo {
  height: 50px;
  top: 4px;
}

// button.codigo-barras-gordo{
// 	height: 50px;
//     top: 4px;
// }
button {
  margin-right: 8px !important;

  outline: 0 !important;
}

button:focus {
  outline: 0 !important;
}

button:disabled {
  cursor: no-drop !important;
}

a:hover {
  text-decoration: none !important;
}

button.chico {
  line-height: 28px !important;
}

button.boton-eliminar {
  color: rgb(187, 0, 0);
}

label {
  margin-bottom: 2px !important;
}

input {
  font-size: 12px;
}

div.editable {
  border: 1px rgb(255, 255, 255) dashed !important;
  padding: 4px;
  cursor: pointer !important;
}

div.editable:hover {
  border: 1px rgb(126, 126, 126) dashed !important;
  color: rgb(29, 90, 0);
  cursor: pointer !important;
}

i.icono-editar {
  color: rgb(49, 146, 3);
  font-size: 15px !important;
}

/* SEPARADOR HR */
hr {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* PARA QUE EL BOTON TENGA EL TAMANO DEL INPUT */
.boton-chico {
  height: 27px;
  margin: 0 6px;
  top: 0px;

  span {
    top: -4px;
    position: relative;
  }
}

.boton-gordo-outline-select {
  height: 44px;
  margin: 0 6px;
  position: relative;
  top: 4px;
}

.boton-gordo-outline {
  height: 42px;
  margin: 0 6px;
  position: relative;
  top: 4px;
}

.boton-outline {
  height: 47px;
  margin: 0 6px;
  top: 4px;
}

.boton-gordo {
  height: 40px;
  margin: 0 6px;
  top: 4px;
}

/*ERRORES*/
div.error {
  color: red;
  &.error-pegado {
    margin-top: -20px;
    margin-bottom: 20px;
  }
}

.mat-form-field-appearance-outline select.ng-invalid.ng-touched {
  /* SELECT MATERIAL CON OULINE */
  border: 0 !important;
}

.mat-mdc-text-field-wrapper:has(div:not(.mat-form-field-infix)>input.ng-invalid.ng-touched) {
  background-color: rgba(255, 245, 245) !important;
}

select.ng-invalid.ng-touched {
  border: red 2px solid;
}
