.print {
	display: none !important;
}

@media print {
	.no-print,
	.no-print * {
		display: none !important;
	}
	.print {
		display: block !important;
	}
	body {
		background-color: #fff !important;
	}
}
