@import "./colores.scss";
/************************************************ MOVIL **************************************/
/*  **  TABS  **  */
div.mat-tab-label-active {
	color: red !important;
}
.movil mat-tab-header div.mat-tab-label-container div.mat-tab-list div.mat-tab-labels {
	justify-content: space-between !important;
	align-items: center !important;
}
div.mat-tab-list {
	padding: 12px 12px 0 12px !important;
}
.mat-tab-label {
	padding: 0 8px !important;
}
.mdc-tab__text-label div {
	padding-top: 2px !important;
}
.movil mat-tab-header {
	position: fixed !important;
	padding: 0 !important;
	z-index: 990 !important;
	bottom: 60px !important;
	height: 70px !important;
	background-color: white !important;
	width: 100% !important;
	align-content: "space-between" !important;
	vertical-align: middle !important;
	text-align: center !important;
	margin: 0 !important;
	left: 0 !important;
	-webkit-box-shadow: 0px -1px 4px 0px rgba(120, 120, 120, 1);
	-moz-box-shadow: 0px -1px 4px 0px rgba(120, 120, 120, 1);
	box-shadow: 0px -1px 4px 0px rgba(120, 120, 120, 1);
}
.movil mat-tab-body {
	margin-bottom: 120px;
	padding: 0 8px !important;
	background-color: white !important;
}

/*HEADER MOVIL*/
.header-top-movil {
	background-color: $oa-principal !important;
	color: white !important;
	position: fixed;
	height: 50px;
	top: 0px;
	font-size: 14px;
	left: 0px;
	width: 100%;
	-webkit-box-shadow: 0px 1px 4px 0px rgba(120, 120, 120, 1);
	-moz-box-shadow: 0px 1px 4px 0px rgba(120, 120, 120, 1);
	box-shadow: 0px 1px 4px 0px rgba(120, 120, 120, 1);
	z-index: 990;
}
.sub-navbar-top-movil {
	background-color: rgb(255, 255, 255) !important;
	position: fixed;
	/* height: 60px; */
	top: 56px;
	font-size: 14px;
	left: 0px;
	width: 100%;
	-webkit-box-shadow: 0px 1px 4px 0px rgba(120, 120, 120, 1);
	-moz-box-shadow: 0px 1px 4px 0px rgba(120, 120, 120, 1);
	box-shadow: 0px 1px 4px 0px rgba(120, 120, 120, 1);
	z-index: 990;
}
/*BOTONERA MOVIL*/
.botones-bottom-movil {
	background-color: $oa-background !important;
	color: white !important;
	position: fixed;
	height: 60px;
	bottom: 0px;
	font-size: 14px;
	left: 0px;
	width: 100%;
	-webkit-box-shadow: 0px -1px 4px 0px rgba(120, 120, 120, 1);
	-moz-box-shadow: 0px -1px 4px 0px rgba(120, 120, 120, 1);
	box-shadow: 0px -1px 4px 0px rgba(120, 120, 120, 1);
	z-index: 990;
}
.botones-bottom-movil button {
	font-size: 16px;
	color: white !important;
}
.botones-bottom-movil i.fa {
	position: relative !important;
	top: -2px;
}
button.btn-eliminar {
	color: rgb(240, 0, 0) !important;
	font-weight: bold;
}
.botones-bottom-movil button[mat-button]:disabled {
	color: rgb(212, 212, 212) !important;
}
/* BOTONES */
button.movil {
	height: 50px !important;
	line-height: 50px !important;
	margin: 0 4px 0 4px !important;
	width: 100% !important;
}
button.back-button-ios {
	position: fixed !important;
	bottom: 25px !important;
	right: 25px !important;
	z-index: 200;
}
button.back-button {
	position: fixed !important;
	top: 75px !important;
	right: 40px !important;
	z-index: 200;
}


/* MATCARD Y DIVS CONTENEDORES*/
mat-card.movil {
	padding: 4px;
	border-radius: 0;
	margin-top: 5px;
	margin-bottom: 5px;
	&:last-child {
		margin-bottom: 80px !important;
	}
	&.mt-90 {
		margin-top: 90px !important;
	}
	&.no-ultimo {
		margin-bottom: 5px !important;
	}
}
mat-card.movil-ultimo {
	padding: 4px !important;
	border-radius: 0 !important;
	margin-bottom: 80px !important;
	&.p-0 {
		padding: 0px !important;
	}
}
div.movil-ultimo {
	padding: 4px !important;
	border-radius: 0 !important;
	margin-bottom: 80px !important;
	&.p-0 {
		padding: 0px !important;
	}
}
/*  ------> MOVIL NUEVO, CARDS */
mat-card.movil-nvo-login {
	padding: 14px !important;
	margin: 4px 16px 16px 16px !important;
}
mat-card.movil-nvo {
	padding: 12px !important;
	margin: 12px 8px 8px 8px !important;
	&:last-child {
		margin-bottom: 80px !important;
	}
	&.no-ultimo {
		margin: 12px 8px 8px 8px !important;
	}
}
mat-card.movil-ultimo-nvo {
	padding: 12px !important;
	margin: 8px 8px 80px 8px !important;
	margin-bottom: 80px !important;
}
div.movil-ultimo-nvo {
	padding: 12px !important;
	margin: 8px 8px 80px 8px !important;
}
/* TEXTO HINT DE MATERIAL */
mat-hint {
	font-size: 14px;
}

/* DIALOGO POPUP */

.dialogo-popup .mat-dialog-container {
	padding: 0px !important;
}
.listado-principal {
	position: absolute;
	top: 145px;
}
.popup-item-espacio {
	min-height: 66px !important;
}

.popup-item {
	width: 100%;
}
.popup-item .popup-item-main {
	align-content: flex-start;
	text-align: left;
	font-size: 14px;
}
.popup-item .popup-item-sub {
	align-content: flex-start;
	text-align: left;
	font-size: 10px;
}
mat-icon {
	position: relative;
	top: -5px;
	color: rgb(98, 98, 98);
}
.agregar-nuevo-popup {
	width: 100%;
	text-align: center !important;
	background-color: #eeeeee;
	color: #000000 !important;
}

/*INPUT*/
.input-movil-popup {
	position: fixed !important;
	top: 0;
	width: 100%;
	padding: 8px 10px 0px 10px;

	font-size: 20px;
	background-color: white;
	z-index: 10;

	.mat-icon {
		top: 1px !important;
		left: -3px !important;
	}
}
.input-movil-popup .mat-form-field-wrapper {
	padding-bottom: 2px !important;
	margin-bottom: 0px;
	font-size: 20px;
}
.input-movil-popup ::-webkit-input-placeholder {
	font-size: 20px;
}
.input-movil-popup ::-moz-placeholder {
	font-size: 20px;
}
.input-movil-popup :-ms-input-placeholder {
	font-size: 20px;
}
.input-movil-popup ::placeholder {
	font-size: 20px;
}
.input-movil-popup mat-label {
	font-size: 20px;
}

/* SELECT  MOVIL */
.input-movil select option {
	font-size: 20px;
}
.input-movil select {
	padding-top: 25px !important;
}

/* INPUT MOVIL */
.input-movil input {
	font-size: 20px;
	padding-left: 8px;
}
.input-movil mat-icon {
	position: relative;
	top: -3px;
}
.input-movil ::-webkit-input-placeholder {
	font-size: 20px;
}
.input-movil ::-moz-placeholder {
	font-size: 20px;
}
.input-movil :-ms-input-placeholder {
	font-size: 20px;
}
.input-movil ::placeholder {
	font-size: 20px;
}
.input-movil mat-label {
	font-size: 20px;
}

/* MAWRGEN PARA DEL TOP PARA SAFAR EL NAVBAR + EL SUB NAVBAR */
.margen-sub-navbar {
	margin-top: 70px;
}
.margen-sub-navbar-2 {
	margin-top: 80px;
}
.margen-sub-navbar-3 {
	margin-top: 90px;
}

/*********************************************************************************************/
