/* REGISTROS */
.anulado {
	color: rgb(170, 170, 170);
}
.input-celda {
	width: 100%;
	height: 94%;
	border: 0;
	text-align: right;
	padding-right: 8px;
	&.borde {
		border: 1px solid #f4f4f4;
		border-radius: 3px;
	}
}
/* MENU CONTEXTUAL PARA FILA */
th.menu-tabla,
td.menu-tabla {
	width: 20px;
	mat-icon {
		margin: 11px 15px 0px -8px !important;
	}
}

/**********************/
/**** TABLA BORDES ****/
/**********************/

/*tabla general*/
table.bordes {
	border: solid 1px #e2e2e2 !important;
}

/*header*/
table.bordes .mat-header-cell {
	font-size: 18px;
	background-color: #d20a31;
	max-height: 45px;
	color: white;
}
/*celda*/
table.bordes .mat-cell {
	font-size: 14px;
	min-height: 48px;
}

/**********************/
/**********************/
/**********************/

/**********************/
/**BORDES DEL RECIBO **/
/**********************/
.mat-cell.borde-izq {
	font-size: 14px;
	min-height: 48px;
	text-align: center;
	border-left: 1px dashed rgb(209, 209, 209);
}
.mat-cell.borde-der {
	font-size: 14px;
	min-height: 48px;
	text-align: center;
	border-right: 1px dashed rgb(209, 209, 209);
}
.mat-cell.borde-der-izq {
	font-size: 14px;
	min-height: 48px;
	text-align: center;
	border-right: 1px dashed rgb(209, 209, 209);
	border-left: 1px dashed rgb(209, 209, 209);
}
/**********************/
/**********************/
/**********************/

/**********************/
/** TABLA REPORTES ****/
/**********************/
//TABLA
table.reporte {
	font-size: 14px;
	width: 100%;
	min-height: 150px !important;

	//ZEBRA
	.mat-row:nth-child(even) {
		background-color: rgb(250, 250, 250);
	}

	.mat-row:nth-child(odd) {
		background-color: rgb(255, 255, 255);
	}

	//CONTENIDO
	tr.mat-row {
		max-height: 38px !important;
		height: 38px !important;
	}
	td.mat-cell:last-of-type {
		padding: 4px;
	}

	//CONTENIDO MOVIL
	.mat-cell.movil {
		padding-left: 6px;
		padding-right: 6px;
	}
	table.movil {
		//tabla movil dentro de una celda
		width: 100%;
	}

	//CABECERA
	tr.mat-header-row {
		font-weight: bold;
		background-color: $oa-blanco-bg;
	}
	.mat-header-cell {
		font-size: 1rem;
		height: 30px;
		color: $oa-principal2;
	}
	//FOOTER
	tr.mat-footer-row {
		font-weight: bold;
		background-color: $oa-blanco-bg;
	}
	.mat-footer-cell {
		font-size: 1rem;
		height: 25px;
		color: $oa-principal2;
	}
	td.mat-footer-cell {
		&.movil {
			padding-top: 6px !important;
			padding-left: 6px !important;
			padding-right: 6px !important;
		}
	}
}
.mat-paginator.reporte {
	font-size: 1rem !important;
	font-weight: bold;
	color: $oa-gris;
}

/**********************/
/**********************/
/**********************/
