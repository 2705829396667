
$oa-principal : #d20a31;
$oa-principal2 : #6d0015;
$oa-principal3 : #f06f88;

$oa-secundario : #0a738c;
$oa-secundario2 : #023b48;
$oa-secundario3 : #539cad;

$oa-accent : #46c309;
$oa-accent2 : #216500;
$oa-accent3 : #90e268;

$oa-accent-secundario : #E1750B;
$oa-accent-secundario2 : #753A00;
$oa-accent-secundario3 : #FFBA76;

$oa-background : #023b48;

$oa-blanco-bg : white;
$oa-blanco : white;

$oa-gris : #6e6e6e;
$oa-gris-claro : #a3a3a3;
$oa-gris-oscuro : #424242;

$oa-color-link: #3e92ff;

