
.app-loading {
	
	background-image: radial-gradient(rgb(114, 114, 114), rgb(61, 61, 61));


	.logo {
		width: 70px;
		height: 70px;
		background: url(../img/icono.svg) center center no-repeat; 
		animation: escalar 3s linear infinite;
	}
}

@keyframes escalar {
	50% {
		transform: scale(0.85);
	}
	100% {
		transform: scale(1);
	}
}
