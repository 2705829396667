.mat-mdc-tab {
  color: #4f4f4f;

  &.mdc-tab-indicator--active {
    color: #dc3545;
  }
}

mat-tab-group {
  .mat-mdc-tab-body-content {
    overflow-y: hidden;
  }
}
