mat-calendar {
  background-color: #fff;
  padding-left: '8px';
  box-shadow: 0 0 5px #ccc;


  .mat-calendar-body-cell-content {
    color: #4f4f4f;
    border-color: #9a9a9a;
  }

  .mat-calendar-body-selected {
    background-color: #d2d2d2;
    color: #ab3535;
    border-color: #4f4f4f;

  }
}

.mat-datepicker-content .mat-calendar {

  height: 369px;
  min-height: 369px;
}
